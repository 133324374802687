



#about .Description{
  max-width:440px;
}

#about{
  padding-bottom:40px;
}

/*************************************************/
/******************** MAIN NAV *******************/
/*************************************************/

#blue_square{
  animation: animation-2 80s infinite linear;
  transform-origin:595px 265px;
}

#yellow_triangle{
  animation: animation-1 120s infinite linear;
  transform-origin:165px 405px;
}

#circle_planet{
  animation: animation-3 8s infinite ease-in-out alternate;
  transform-origin:465px 105px;
}

#triangle_planet{
  animation: animation-4 14s infinite ease-in-out alternate;
  transform-origin:265px 105px;
}

#square_planet{
  animation: animation-4 14s infinite ease-in-out alternate;
  transform-origin:145px 195px;
}

#about_portrait .portrait_svg{
  width:100%;
  box-sizing:border-box;
  padding-left:40%;
  position:absolute;
  top:-10px;
}

#design_stack_title{
  display:block;
  text-align:center;
}

#design_stack_list{
  margin-left:50px;
  width:45%;
  display:block;
  margin-left:55%;
  color:#F558CD;
}

#design_stack_logos{
  max-width: 220px;
    width: 50%;
    margin-top: 5px;
    margin-left: 10px;
    opacity: .5;
    position: absolute;
}


@keyframes animation-1 {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes animation-2 {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(-360deg);
  }
}

@keyframes animation-3 {
    0% {
        transform: rotate(-10deg);
    }
  
    100% {
        transform: rotate(-35deg);
    }
}

@keyframes animation-4 {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(45deg);
  }
}

@media(max-width:1400px){

  #about_portrait .portrait_svg {
    top: -50px;
    margin-left:12%;
  }
  
  }

@media(max-width:1200px){

#about_portrait .portrait_svg {
  width: 80%;
  box-sizing: border-box;
  padding:0px;
  margin-left:12%;
  position: absolute;
  top: 480px;
}
#about{
  height:1200px;
}

}

@media(max-width:1000px){
  #about_portrait .portrait_svg{
    padding-left:0px!important;
    margin-top:400px;
    margin-left:-80px;
    width:calc(100% + 120px)!important;
  }
  #about_portrait{
    display:none;
  }
  #about{
    height:auto;
  }

}

@media(max-width:600px){
}


@media(max-width:450px){
}