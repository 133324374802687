




.cases{
  display:flex;
  flex-flow: row wrap;
}




/*************************************************/
/******************** MAIN NAV *******************/
/*************************************************/



.case-item{
  width: calc(33.33% - 70px);
  padding:35px;
  padding-bottom:50px;
  padding-top:20px;
}
.case-item:nth-child(3n+1){
  padding-left:0px;
  padding-right:70px;
  clear:left;
}
.case-item:nth-child(3n+3){
  padding-right:0px;
  padding-left:70px;
  clear:right;
}

.case-item .Img{
  width:100%;
  transform:perspective(10cm) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 4px 0px 10px rgba(0,0,0,0);
  transition: 1s transform;
}

.case-item:not(.hovered) .Img{
  transform:perspective(10cm) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transition: 1s transform;
}

.case-item:hover .Img{
  transition:0s transform;
  transition: .2s transform;
}

.case-item.hovered .Img{
  
  pointer-events:none;
}

/*
.case-item .Img:hover{
  transition: .4s transform, 1s box-shadow;
  box-shadow: 4px 0px 30px rgba(0,0,0,.1);
}*/

.case-item .Name{
  font-weight:bold;
  font-size:1.15em;
  margin:20px 0px 0px 0px;
  transition: .25s color;
}

.case-item:hover .Name{
  color:#0DC5FF;
}

.case-item .Description{
  font-size:.9em;
  font-weight:lighter!important;
  font-family:"Founders Grotesk";
}

.case-item:hover{
  cursor:pointer;
}

.case-item .WorkType{
  font-family:"Greycliff";
  font-weight:600;
  font-style:italic;
  font-size:.8em;
  margin-bottom:15px;
}

.case-item .WorkType .company{
  color: #adb0be;
}



/*
.halftones:hover{
  filter: contrast(100%);
}

.halftones:hover > * {
  filter: brightness(1) blur(0px);
}

.halftones:hover::after {
  opacity:0;
  transform: rotate(41.25deg) scale(.5);
  transition-delay:.2s;
}


.halftones {
  background: white;
  position: relative;
  filter: contrast(5000%);
  overflow: hidden;
  transform: translateZ(0);
  transition: .2s ease-in-out all;
}

.halftones > * {
  filter: brightness(0.35) blur(3px);
  transition: .3s ease-in-out all;
}

.halftones::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background-blend-mode: multiply;
  background:
      radial-gradient(8px 8px, cyan, white),
      radial-gradient(8px 8px, magenta, white),
      radial-gradient(8px 8px, yellow, white);
  background-size: 8px 8px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: screen;
  pointer-events: none;
  opacity:1;
  transform: rotate(21.25deg) scale(1);
  transition: .3s ease-in-out all;
  transition-delay:0s;
  z-index: 1;
}
*/





@keyframes animation-overlay {
  0% {
      transform: rotate(21.25deg) scale(.5);
  }

  3% {
    transform: rotate(50.25deg) scale(6);
  }

  4% {
    transform: rotate(60.25deg) scale(7.35);
  }

  4.5% {
    transform: rotate(65deg) scale(7.75);
  }

  5% {
    transform: rotate(69deg) scale(7.95);
  }

  5.5% {
    transform: rotate(72deg) scale(8.10);
  }

  100% {
      transform: rotate(650deg) scale(9);
  }
}

@keyframes animation-filter {
  0% {
      filter: brightness(0.5) blur(20px);
  }

  100% {
      filter: brightness(0.5) blur(20px);
  }
}





/** WORKING HOVER **/

/*

.case-item:hover .halftones {
  background: white;
  position: relative;
  filter: contrast(25000%);
  overflow: hidden;
  transform: translateZ(0);
}

.case-item .halftones > * {
  border-radius:0px;
  transform:scale(1);
  transition: .4s ease-out border-radius, .5s ease-out transform;
}

.case-item:hover .halftones > * {
  filter: brightness(0.3) blur(3px);
  border-radius:200px;
  transform:scale(1.05);
  transition: 2s ease-out border-radius, 1.5s ease-out transform;
}

.case-item:hover .halftones::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  opacity:1;
  background-blend-mode: multiply;
  background:
      radial-gradient(8px 8px, cyan, white),
      radial-gradient(8px 8px, magenta, white),
      radial-gradient(8px 8px, yellow, white);
  background-size: 8px 8px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: screen;
  pointer-events: none;
  transform: rotate(11.25deg);
  transition: 1s ease-in-out all;
  z-index: 1;
  animation: animation-overlay 50s infinite linear;
}


@keyframes animation-overlay {
  0% {
      transform: rotate(21.25deg) scale(.5);
  }

  3% {
    transform: rotate(50.25deg) scale(6);
  }

  4% {
    transform: rotate(60.25deg) scale(7.35);
  }

  4.5% {
    transform: rotate(65deg) scale(7.75);
  }

  5% {
    transform: rotate(69deg) scale(7.95);
  }

  5.5% {
    transform: rotate(72deg) scale(8.10);
  }

  100% {
      transform: rotate(650deg) scale(9);
  }
}

@keyframes animation-filter {
  0% {
      filter: brightness(0.5) blur(20px);
  }

  100% {
      filter: brightness(0.5) blur(20px);
  }
}

*/

@media(max-width:1200px){

  .case-item{
    width: calc(50% - 70px);
    padding:35px;
    padding-bottom:50px;
    padding-top:20px;
  }
  .case-item:nth-child(2n+1){
    padding-left:0px;
    padding-right:70px;
    clear:left;
  }
  .case-item:nth-child(2n+2){
    padding-right:0px;
    padding-left:70px;
    clear:right;
  }

}

@media(max-width:1000px){

  .case-item{
    width: calc(50% - 40px);
    padding:20px;
    padding-bottom:50px;
    padding-top:20px;
  }
  .case-item:nth-child(2n+1){
    padding-left:0px;
    padding-right:40px;
    clear:left;
  }
  .case-item:nth-child(2n+2){
    padding-right:0px;
    padding-left:40px;
    clear:right;
  }
  #case{
    padding-top:0px;
  }

}

@media(max-width:600px){

  .case-item{
    width: calc(100% - 80px);
    padding-left:40px!important;
    padding-right:40px!important;
    padding-bottom:40px;
    padding-top:20px;
  }

}

@media(max-width:450px){

  .case-item{
    width: calc(100% - 40px);
    padding-left:20px!important;
    padding-right:20px!important;
    padding-bottom:40px;
    padding-top:20px;
  }

}