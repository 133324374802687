


/*************************************************/
/******************** MAIN NAV *******************/
/*************************************************/



.pretty_title{
  position: absolute;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  top: 0px;
  height:100%;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 20px;
  font-weight:bold;
  font-size:1.6em;
  color:#2A2D31;
  text-decoration:none;
  filter: blur(15px);
  opacity:0;
  z-index:10000;
  transition: 1.5s filter, 1s opacity;
  /*mix-blend-mode: overlay;*/
}

.halftone{
  overflow:hidden;
  cursor:pointer;
}

.pretty_title span{
  font-size:.6em;
  font-weight:normal;
  display:inline;
}

.halftone img{
  transition: .5s all;
  opacity:1;
  filter: blur(0px);
  transform: scale(1);
}

.halftone:hover .pretty_title{
  opacity:1;
  filter: blur(0px);
  transition: .5s filter, .5s opacity;
}

.halftone:hover img{
  opacity:.3;
  filter: blur(3px);
  transform: scale(1.1);
}


.flexbin{
  padding-bottom:30px;
}

section.hidden .flexbin>a{
  opacity:0;
  margin-top:50px;
}

.flexbin>a{
  position:relative;
  opacity:1;
  transition:opacity .4s ease-out, margin-top .4s ease-out;
  margin-top:10px;
  animation: fadein 1.5s;
  display:block;
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

.flexbin>a:nth-child(0){
  transition-delay:.1s;
}
.flexbin>a:nth-child(1){
  transition-delay:.2s;
}
.flexbin>a:nth-child(2){
  transition-delay:.3s;
}
.flexbin>a:nth-child(3){
  transition-delay:.4s;
}
.flexbin>a:nth-child(4){
  transition-delay:.5s;
}
.flexbin>a:nth-child(5){
  transition-delay:.6s;
}
.flexbin>a:nth-child(6){
  transition-delay:.7s;
}
.flexbin>a:nth-child(7){
  transition-delay:.8s;
}
.flexbin>a:nth-child(8){
  transition-delay:.9s;
}
.flexbin>a:nth-child(9){
  transition-delay:1s;
}
.flexbin>a:nth-child(10){
  transition-delay:1.1s;
}
.flexbin>a:nth-child(11){
  transition-delay:1.2s;
}
.flexbin>a:nth-child(12){
  transition-delay:1.3s;
}
.flexbin>a:nth-child(13){
  transition-delay:1.4s;
}
.flexbin>a:nth-child(14){
  transition-delay:1.5s;
}
.flexbin>a:nth-child(15){
  transition-delay:1.6s;
}
.flexbin>a:nth-child(16){
  transition-delay:1.7s;
}
.flexbin>a:nth-child(17){
  transition-delay:1.8s;
}
.flexbin>a:nth-child(18){
  transition-delay:1.9s;
}
.flexbin>a:nth-child(19){
  transition-delay:2s;
}
.flexbin>a:nth-child(20){
  transition-delay:2.1s;
}
/*

.halftone:hover {
  background: white;
  position: relative;
  filter: contrast(25000%);
  overflow: hidden;
  transform: translateZ(0); 
}

.halftone > * {
  border-radius:0px;
  transform:scale(1);
  transition: .4s ease-out border-radius, .5s ease-out transform;
}

.halftone:hover > * {
  filter: brightness(0.3) blur(3px);
  border-radius:200px;
  transform:scale(1.05);
  transition: 2s ease-out border-radius, 1.5s ease-out transform;
}

.halftone:hover::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  opacity:1;
  background-blend-mode: multiply;
  background:
      radial-gradient(8px 8px, cyan, white),
      radial-gradient(8px 8px, magenta, white),
      radial-gradient(8px 8px, yellow, white);
  background-size: 8px 8px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: screen;
  pointer-events: none;
  transform: rotate(11.25deg);
  transition: 1s ease-in-out all;
  z-index: 1;
  animation: animation-overlay 50s infinite linear;
}


@keyframes animation-overlay {
  0% {
      transform: rotate(21.25deg) scale(.5);
  }

  3% {
    transform: rotate(50.25deg) scale(6);
  }

  4% {
    transform: rotate(60.25deg) scale(7.35);
  }

  4.5% {
    transform: rotate(65deg) scale(7.75);
  }

  5% {
    transform: rotate(69deg) scale(7.95);
  }

  5.5% {
    transform: rotate(72deg) scale(8.10);
  }

  100% {
      transform: rotate(650deg) scale(9);
  }
}

*/



/*

https://codepen.io/coreh/pen/LQJBLa

.halftones:hover{
  transition: 1s ease-in-out all;
  filter: contrast(100%);
}



.halftones {
  background: white;
  position: relative;
  filter: contrast(25000%);
  overflow: hidden;
  transform: translateZ(0); 
  transition: 1s ease-in-out all;
}

.halftones:hover > img{
  filter: brightness(1) blur(4px);
  opacity:0;
}

.halftones > img {
  filter: brightness(.4) blur(4px);
  transition: 1s ease-in-out all;
}

.halftones:hover::after{
  transition: 1s ease-in-out all;
  transform: rotate(11.25deg) scale(5);
  content:'';
  opacity:0;
}


.halftones::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background-blend-mode: multiply;
  background:
      radial-gradient(8px 8px, cyan, white),
      radial-gradient(8px 8px, magenta, white),
      radial-gradient(8px 8px, yellow, white);
  background-size: 4px 4px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: screen;
  pointer-events: none;
  transform: rotate(11.25deg) scale(2);
  transition: 1s ease-in-out all;
  z-index: 1;
  opacity:1;
}
*/

/*

  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background-blend-mode: multiply;
  background:
      radial-gradient(4px 4px, cyan, white),
      radial-gradient(4px 4px, magenta, white),
      radial-gradient(4px 4px, yellow, white);
  background-size: 4px 4px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: screen;
  pointer-events: none;
  transform: rotate(11.25deg);
  transition: 1s ease-in-out transform;
  z-index: 1;

  */


/*

.halftones:hover {
  background: white;
  position: relative;
  filter: contrast(25000%);
  overflow: hidden;
  transform: translateZ(0); 
}

.halftones:hover > img {
  filter: brightness(0.5) blur(4px);
  animation: 10s animation-filter infinite alternate;
}


.halftones::after{
  transform:rotate(0deg) scale(10);
  transition: 1s ease-in-out all;
}

.halftones:hover::after {
  animation: 100s animation-overlay infinite linear;
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  background-blend-mode: multiply;
  background:
      radial-gradient(4px 4px, cyan, white),
      radial-gradient(4px 4px, magenta, white),
      radial-gradient(4px 4px, yellow, white);
  background-size: 4px 4px;
  background-position: 0 -3px, 0px -3px, 0px -3;
  mix-blend-mode: color-dodge;
  pointer-events: none;
  transform: rotate(11.25deg) scale(10);
  transition: 2s ease-in-out all;
  z-index: 1;
}

@keyframes animation-overlay {
  0% {
      transform: rotate(361.25deg) scale(6);
  }

  100% {
      transform: rotate(11.25deg) scale(6);
  }
}

@keyframes animation-filter {
  0% {
      filter: brightness(0.5) blur(40px);
  }

  100% {
      filter: brightness(0.5) blur(4px);
  }
}
*/