

.Project-viewer {
  font-family: 'Founders Grotesk';
  font-size:18px;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  background:white;
  z-index:100000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1.5s, opacity 0.5s ease-out;
  pointer-events:none;
  overflow:scroll;
  transition-delay: 0s, 200ms;
}

.Project-viewer.true{
  display:block;
  visibility: visible;
  opacity: 1;
  pointer-events:all;
  transition: visibility 0.3s, opacity 0.3s ease-out;
  transition-delay: 0s, 000ms;
}

.Project-container{
  position:relative;
  z-index:10;
  padding-bottom:80px;
}

.Project-viewer .Background{
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index:0;
  cursor: url(img/back_cursor.png) 60 20, auto;
}

#Project-scroll.Pretty_BG{
  background:#111!important;
}

.project-wrap{
  position:relative;
}

.Project-header-placeholder{
  height: 460px;
  position:relative;
  width:100%;
}

.Pretty-container{
  padding-bottom:100px;
}





.Pretty-container .Info{
  position:absolute;
  padding:30px;
  border-radius:5px;
  box-shadow: 0px 4px 20px rgba(0,0,15,.15);
  background:white;
  right:30px;
  top:80px;
  transition: all .3s;
  opacity:1;
  z-index:10000;
  width: calc(100% - 120px);
  max-width:450px;
}

.Pretty-container .Info.visible_false{
  opacity:0;
}

.Pretty-container .Title{
  position:relative;
  margin-top:0px;
  margin-bottom:10px;
  box-sizing:border-box;
  width:100%;
  max-width:450px;
  font-size:1.7em;
  padding-right:50px;
}

.Pretty-container .Minimize{
  font-weight:bold;
  font-size:2em;
  line-height:0px;
  height:35px;
  width:35px;
  text-align:center;
  line-height:44px;
  position:absolute;
  top:0px;
  right:0px;
  margin:23px;
  border-radius:40px;
  transition: all .2s;
  cursor:pointer;
}

.Pretty-container .Minimize:hover{
  background:#e9e9e9;
}

.Caption_Triangle {
  width: 31px;
  padding-bottom: 20px; /* = width / 1.41 */
  position: relative;
  overflow: hidden;
  position:absolute;
  top:-20px;
  right:61px;
}

.Caption_Triangle:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform-origin: 0 100%;
  transform: rotate(45deg);
  border-top-left-radius:2px;
}

.Pretty-container .Description{
  position:relative;
  padding-top:0px;
  max-width:450px;
  width:100%;
  font-size:1em;
  box-sizing:border-box;
}




.pretty_info_button.visible_true .button_inner{
  background:#ff7492;
  color:white;
}

.pretty_info_button{
  position:absolute;
  right:70px;
  top:5px;
  width:70px;
  height:70px;
  pointer-events: all;
  cursor:pointer;
  z-index:1000000;
  border-radius:70px;
}

.pretty_info_button .button_inner{
  margin: 15px;
    width: 35px;
    height: 35px;
    box-shadow: 0px 4px 20px rgb(0 0 15 / 15%);
    /*border: 2px solid #ff7492;*/
    border-radius: 100px;
    position: absolute;
    background: white;
    font-size: 30px;
    font-family: serif;
    color: #1C2259;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    transition: all .2s;
}

.close_pretty_button{
  position:absolute;
  right:10px;
  top:5px;
  width:70px;
  height:70px;
  pointer-events: all;
  cursor:pointer;
  z-index:1000000;
  border-radius:70px;
}

.close_pretty_button .button_inner{
  margin:15px;
  width:35px;
  height:35px;
  box-shadow: 0px 4px 20px rgba(0,0,15,.15);
  /*border: 2px solid #ff7492;*/
  border-radius:100px;
  position:absolute;
  background:white;
  transition: all .2s;
}

.close_pretty_button .left,.close_pretty_button .right{
  width:60%;
  margin:20%;
  transform:rotate(45deg);
  height:2px;
  background:#1C2259;
  margin-top:17px;
  border-radius:10px;
  position:absolute;
  transition: all .2s;
}

.close_pretty_button:hover .button_inner, .pretty_info_button:hover .button_inner{
  background:#ff7492;
  color:white;
}


.close_pretty_button:hover .left,.close_pretty_button:hover .right{
  background:#1C2259;
}

.close_pretty_button .left{
  transform:rotate(45deg);
}

.close_pretty_button .right{
  transform:rotate(-45deg);
}

.close_pretty_button:hover .left{
  /*transform:rotate(135deg);*/
  background:white;
}

.close_pretty_button:hover .right{
  /*transform:rotate(45deg);*/
  background:white;
}


.Pretty-container iframe.hero_content{
  width:100%;
  max-width:1000px;
  margin: auto;
  left:0;
  right:0;
  position:absolute;
  top:100px;
}

.Pretty-container img.hero_content{
  width: auto;
    max-width: 100%;
    margin: auto;
    display: block;
    max-height:calc(100vh);
    left:0;
    right:0;
    position:absolute;
    top:0;
    bottom:0;
}



/*************************************************/
/**************** MAIN PAGE MENU *****************/
/*************************************************/



/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  
}

@media(max-width:1200px){
}

@media(max-width:1000px){

}

@media(max-width:800px){
  
}

@media(max-width:600px){
  .Pretty-container .Title{
    position:relative;
    margin-top:0px;
    margin-bottom:10px;
    box-sizing:border-box;
    width:100%;
    max-width:450px;
    font-size:1.3em;
  }
}

@media(max-width:500px){

}

@media(max-width:450px){

}