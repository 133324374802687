


.page-header {
  height:calc(100vh);
  min-height:600px;
  width:100%;
  text-align:left;
  position:relative;
  font-family:'Greycliff', sans-serif;
  z-index:150;
  background: rgb(255,253,251);
  background: linear-gradient(180deg, rgba(255,253,251,1) 50%, rgba(249,247,248,1) 100%);
}

#home>.page-container{
  pointer-events:none;
}

.logo-name{
  font-family:'Greycliff', sans-serif;
  font-size:7em;
  font-weight:500;
  position:absolute;
  top:80px;
  margin:0px;
  z-index:305;
  filter:blur(0px);
  transform:scale(1);
  transition:1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) filter,1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) transform,1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) opacity;
  transition-delay:0s;
  opacity:1;
  text-shadow: 5px 5px rgb(0 0 0 / 20%);
}

.logo-name.unfocused{
  filter:blur(11px);
  transform:scale(1.13);
  transition:.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) filter,.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) transform,.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) opacity;
  opacity:.25;
  transition-delay:0s;
}

.logo-name.unfocused2{
  filter:blur(15px);
  transform:scale(1.07);
  transition:.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) filter,.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) transform,.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) opacity;
  opacity:.25;
  transition-delay:0s;
}

.intro{
  position:absolute;
  left:0px;
  bottom:80px;
  font-family: 'Founders Grotesk', sans-serif;
  z-index:305;
}

.intro .job-title{
  font-size:1.7em;
  font-weight:600;
  margin-bottom:8px;
}

.intro .sub-text{
  margin-bottom:8px;
}

.intro .location{
  color:#adb0be;
  margin-top:12px;
}



/*************************************************/
/********************** SVG **********************/
/*************************************************/


#concept_map{
  width:100%;
  height:100%;
}

#map_wrap{
  opacity:1;
  transition: .5s opacity,.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) transform;
  transform:scale(.97);
  height:100%;
  width:100%;
  position:absolute;
}

#map_wrap.faded{
  opacity:0;
}

#map_wrap.zoomed{
  transform:scale(1.03);
}

#map_wrap.zoomed.zoomed2{
  transform:scale(1)!important;
}

#sketch_start{
  z-index:10;
}

#ripple, #ripple2, #ripple3, #ripple4{
  transform-origin: 65% 45%;
  animation: ripple 4s cubic-bezier(.49,.05,.32,1.04) infinite;
  z-index:0;
  pointer-events:none;
}

#ripple.quick, #ripple2.quick,#ripple3.quick, #ripple4.quick{
  animation: ripple 4s cubic-bezier(.49,.05,.32,1.04) infinite;
}

#ripple2{
  animation-delay: 2s;
}

#ripple2.quick{
  animation-delay: 1s;
}
#ripple3.quick{
  animation-delay: 2s;
}
#ripple4.quick{
  animation-delay: 3s;
}

@keyframes ripple {
  0%{
    transform: scale(1.05);
    opacity:.9;
  }
  100% {
    transform: scale(4);
    opacity:0;
  }
}

#map_title{
  width: 300px;
  z-index: 205;
  position: absolute;
  right: 90px;
  top: 10%;
  font-size: .9em;
  background: rgba(255,255,255,.6);
  /*color: #0DC5FF;*/
  box-sizing: border-box;
  padding: 30px 0px;
  /*border-bottom: 1px solid #0DC5FF;*/
  box-shadow: 0px 0px 50px 50px rgba(255,255,255,.6);
  animation: fadein 1s;
  color: #212C3B!important;
  border-color:#212C3B!important;
  transform:scale(1);
  filter:blur(0px);
  transition:1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) filter,1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) transform;
}

.faded #map_title{
  transform:scale(.95);
  filter:blur(15px);
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

#main_content{
  position:absolute;
  width: 100%;
  height: 100%;
}

#map_title h1, #map_title p{
  margin:0px;
}
#map_title p{
  font-size:1.4em;
}

#map_title .map_link{
  position:relative;
  display:block;
  margin-top:-27px;
  width:100%;
  text-align:right;
  text-decoration:none;
  font-size:1.4em;
  color:#212C3B;
  padding-right:10px;
  box-sizing:border-box;
}

#map_title .map_link.hide{
  display:none;
}

#map_title .Description{
  font-size:1.1em;
  padding-top:20px;
  width:100%;
  margin-top:20px;
  color: #212C3B;
  font-family: 'Founders Grotesk', sans-serif;
}




/*************************************************/
/******************** MAIN NAV *******************/
/*************************************************/


#nav>.page-container{
  display:inline;
}

#nav.sticky>.page-container{
  display:block;
}

#nav{
  width:100%;
  height:80px;
  position:absolute;
  right:0px;
  pointer-events:auto;
  transition: background .5s;
}

#nav_wrapper{
  width:100%;
  height:80px;
  position:absolute;
  bottom:100px;
  right:0px;
  z-index:400;
}

#nav.sticky{
  position:fixed;
  top:0px;
  padding-top:0px;
  background:white;
  padding-bottom:10px;
}

#nav .page-container{
  transition: all 1s;
  box-shadow:0px 15px 20px -20px rgba(0,0,0,0);
}

#nav.sticky .page-container{
  background: rgba(255,255,255,.99);
  padding-bottom:10px;
  box-shadow:0px 15px 21px -20px rgba(0,0,0,.1);
  transition: all 1s;
}

/*
#App .nav-item, #App .nav-item.active, #App .nav-item:hover{
  color:#fff;
}
#App .nav-item::after{
  border-bottom:2px solid #fff;
}

#App.scrolled .nav-item, #App.scrolled .nav-item.active, #App.scrolled .nav-item:hover{
  color:#212C3B;
}
#App.scrolled .nav-item::after{
  border-bottom:2px solid #212C3B;
}*/


.nav .nav-item{
  font-family:'Noto Sans', sans-serif;
  float:right;
  height:80px;
  line-height:80px;
  padding:0px 20px;
  font-size:1em;
  font-weight:500;
  position:relative;
  cursor:pointer;
  color:#adb0be;
  transition: all .5s;
}

.nav .nav-item::after{
  content:'';
  width:0px;
  position:absolute;
  left:50%;
  margin-left:0px;
  bottom:20px;
  border-bottom:2px solid #212C3B;
  transition: all .5s;
}

.nav .nav-item:hover{
  color:#212C3B;
  opacity:1;
}

.nav .nav-item:hover::after{
  width:20px;
  margin-left:-10px;
}

.nav .nav-item.active{
  color:#212C3B;
  opacity:1;
}

.nav .nav-item.active::after{
  width:20px;
  margin-left:-10px;
}

#nav .nav-item:first-child::after{
  margin-left:10px!important;
  
}

#nav .nav-item:first-child:hover::after{
  margin-left:0px!important;
  
}

#nav .nav-item.active:first-child::after{
  margin-left:0px!important;
}

#nav .nav-item:first-child{
  padding-right:0px;
}




#mobile_header_bg{
  position: absolute;
  left: 50%;
  margin-left: -325px;
  margin-top: -70px;
  display:none;
}
#header_portrait{
  display:none;
  position:absolute;
  top:300px;
  width:100%!important;
  max-width:500px;
  right:50%;
  margin-right:-250px;
}
#header_portrait .portrait_svg{
  position:absolute;
  width: 100%!important;
  box-sizing:border-box;
}




#gray_square{
  animation: animation-2-1 40s infinite linear;
  transform-origin:500px 605px;
}

#gray_triangle{
  animation: animation-1-1 80s infinite linear;
  transform-origin:150px 240px;
}



@keyframes animation-1-1 {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes animation-2-1 {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(-360deg);
  }
}



@media(max-width:1400px){
  #nav_wrapper .nav-item{
    padding:0px 10px;
    font-size:1em;
  }
  .intro .job-title{
    font-size:1.5em;
  }
  .intro .sub-text{
    font-size:.8em;
  }
  
}

@media(max-width:1200px){
  .intro{
    top:420px;
    border-top:1px solid #C7D0DC;
    padding-top:50px;
    bottom:auto;
  }
  #nav_wrapper {
    width: 490px;
    right: 50%;
    margin-right: -230px;
  }
  
  .logo-name{
    margin:0px!important;
    font-size:6em;
  }

  .intro{
    top:320px;
    border:none;
  }
  .intro .sub-text{
    color:#9599b1;
    font-size:1em;
  }
  .job-title{
    padding-bottom:10px;
  }
  

}

@media(max-width:1000px){
  .logo-name{
    font-size:5em;
    top:30px;
    margin-left:-2px!important;
  }
  .intro{
    top:230px;
    border:none;
  }
  
  #concept_map{
    display:none;
  }
  #header_portrait{
    display:block;
  }
  #header_portrait{
    top:80px;
    right:30%;
    margin-right:-300px;
  }
  .page-header{
    min-height:900px;
    background:none;
  }
  
}

@media(max-width:800px){
  #nav_wrapper{
    width: 445px;
    margin-right: -212px;
    display:none;
  }
  #nav_wrapper .page-container{
    display:block;
    margin:0px;
    width:100%;
  }

  #mobile_header_bg{
    display:block;
  }
  .logo-name{
    top:45px;
    font-size:4.5em;
  }
  .intro{
    border: none;
    top:190px;
  }
  .intro .job-title{
    font-size:1.5em;
    margin-bottom:5px;
  }
  #header_portrait{
    top:260px;
    right:50%;
    margin-right:-250px;
  }

}

@media(max-width:600px){
  #nav{
    bottom:0px!important;
    top:auto!important;
    position:fixed;
    right: 50%;
    margin-right: -175px;
  }
  .logo-name{
    font-size:4em;
    top:50px;
    font-weight:600;
  }
  .intro{
    top:165px;
  }
  .intro .job-title{
    font-size: 1.8em;
    margin-bottom: 3px;
    margin-top: 10px;
    font-weight: 300;
  }
  .intro .location{
    display:none;
  }
  #header_portrait{
    top:200px;
    right: 50%;
    margin-right: -250px;
    width: 120%!important;
    margin-left: -35px;
    overflow:hidden;
    min-height:600px;
  }
  
  .intro .sub-text{
    display:none;
  }

  #nav_wrapper{
    width: 350px;
    left:0px;
    right:auto;
    margin-left:-20px;
    margin-right:auto;
    top:400px;
  }
  #nav_wrapper .nav-item.home{
    display:none
  }
  #nav_wrapper .nav-item{
    font-size:.9em;
  }

  .page-header {
    height:calc(100vh);
    min-height:800px;
  }
  #mobile_header_bg{
    zoom:.9;
    margin-left:-315px;
    margin-top:-50px;
  }
  

}


@media(max-width:450px){
  .logo-name{
    font-size:3.4em;
    top:40px;
  }
  .intro{
    top:140px;
  }
  .intro .job-title{
    font-size:1.5em;
  }
  
  #header_portrait{
    top:160px;
    right: 0px;
    left: 0px;
    margin-right:-20px;
    width: 110%!important;
    margin-left: -20px;
  }
  .page-header {
    height:calc(100vh - 40px);
    min-height:750px;
  }
}

@media(max-width:425px){
  #header_portrait{
    top:155px;
  }
}

@media(max-width:400px){
  #header_portrait{
    top:145px;
  }
}