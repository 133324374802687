

#foot_info{
  width:100%;
  text-align:center;
  padding-top:50px;
  padding-bottom:90px;
}

#contact{
  background: rgb(255,253,251);
  background: linear-gradient(180deg,  rgba(249,247,248,1) 0%, rgba(255,255,255,1) 50%);
}

#contact .page-container{
  overflow: hidden;
}

#contact .Description{
  max-width:380px;
}

#contact .Description icon{
  position:relative;
  line-height:.5em;
}


.Contact-form{
  width:100%;
  max-width:500px;
  float:right;
  position:relative;
  top:-85px;
  padding-right:11%;
}


.Contact-form input{
  width:calc(50% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  color:#2F373F;
  height:60px;
  background:none;
  padding:10px 20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input::placeholder, .Contact-form textarea::placeholder{
  color:#bbb;
}

.Contact-form textarea{
  width:calc(100% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  background:none;
  color:#222222;
  height:150px;
  padding:20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input[type="Submit"]{
  background:white;
  width:120px;
  border-radius:5px;
  height:50px;
  box-shadow: 1px 1px 0px #2F373F;
  float:right;
  transition: background-color .3s;
  cursor:pointer;
}

.Contact-form input[type="Submit"]:hover{
  background-color: #eee;
  color:#2F373F;
}



/*************************************************/
/******************** MAIN NAV *******************/
/*************************************************/

@media(max-width:1400px){
  .Contact-form{
    width:100%;
    max-width:500px;
    float:right;
    position:relative;
    top:-85px;
    padding-right:0px;
  }
}


@media(max-width:1200px){

  .Contact-form{
    padding:0px;
    padding-right:0px;
    margin:auto;
    float:none;
    margin-top:120px;
  }

  #contact .Description, #contact .Title{
    margin:auto;
    max-width:500px!important;
  }
  #contact .Description{
    margin-top:20px;
  }

}

@media(max-width:600px){
  #contact .Description{
    margin-top:10px;
  }
}