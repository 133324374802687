/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');*/

@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Bold/FoundersGrotesk-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Bold/FoundersGrotesk-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: url('./fonts/Founders_Semibold/FoundersGrotesk-Semibold.woff2') format('woff2'),
      url('./fonts/Founders_Semibold/FoundersGrotesk-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Medium/FoundersGrotesk-Medium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Medium/FoundersGrotesk-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Light/FoundersGrotesk-Light.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Light/FoundersGrotesk-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}



@font-face {
  font-family: 'Greycliff';
  src: url('./fonts/Greycliff/greycliffcf-demibold-webfont.woff2') format('woff2'),
       url('./fonts/Greycliff/greycliffcf-demibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Greycliff';
  src: url('./fonts/Greycliff/GreycliffCF-Bold.woff') format('woff'),
       url('./fonts/Greycliff/GreycliffCF-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Greycliff';
  src: url('./fonts/Greycliff/GreycliffCF-BoldOblique.woff') format('woff'),
       url('./fonts/Greycliff/GreycliffCF-BoldOblique.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

.App {
  font-family: 'Founders Grotesk';
  font-size:18px;
  font-weight:300;
  color:#2A2D31;
  color:#2C2E44;
  background: #fff;
  /*transition: .8s color, .8s background;*/
}
/*
.App.scrolled{
  color:#2A2D31;
  background: #fff;
}*/


html {
  scroll-behavior: smooth;
  font-weight:300;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative;
  width:100%;
}

a{
  color:#ff7492;
  text-decoration:none;
  font-weight:400;
}

a:hover{
  text-decoration:underline;
}

body.noScroll,html.noScroll{
  overflow:hidden;
}


.hidden:not(.App-header){
  opacity:0.01!important;
  transition: opacity 1.5s!important;
}

.fade-in-element:not(.App-header){
  transition: opacity 1.5s!important;
  opacity:1;
}



.page-container{
  margin:auto;
  width:calc(100% - 240px);
  height:100%;
  display: block;
  position:relative;
  margin-top:0px;
  z-index:10;
  max-width:1300px;
}




/*************************************************/
/*************** SECTION ELEMENTS ****************/
/*************************************************/



section{
  width:100%;
  box-sizing:border-box;
  padding-top:100px;
}



.section-intro{
  position:relative;
  padding-bottom:40px;
  z-index:10;
}

.section-intro .Title{
  font-family:'Greycliff', sans-serif;
  font-size:2.2em;
  margin-bottom:0px;
  overflow:hidden;
  height:55px;
  font-weight:600;
}

h1 span{
  position:relative;
  transition: .35s top ease-out, .25s opacity ease-out;
  top:0px;
  opacity:1;
}

section.hidden h1 span, header.hidden h1 span{
  top:55px;
  opacity:0;
}

h1 span:nth-child(1){
  transition-delay:.3s;
}

h1 span:nth-child(2){
  transition-delay:.45s;
}

h1 span:nth-child(3){
  transition-delay:.6s;
}

h1 span:nth-child(4){
  transition-delay:.75s;
}

h1 span:nth-child(5){
  transition-delay:.9s;
}

.section-intro .Description{
  max-width:300px;
}



.see-more{
  position:relative;
  width:200px;
  text-align:center;
  left:50%;
  margin-left:-100px;
  font-size:1.1em;
  height:60px;
  color:#2F373F;
  padding-top:20px;
  font-family:'Greycliff CF', sans-serif;
  letter-spacing:3px;
  font-weight:400;
  transition: .25s color;
  margin-top:10px;
}

.see-more:hover{
  cursor:pointer;
  color:#0DC5FF;
}

.see-more span{
  top: -6px;
  position: relative;
  font-size:1.2em;
}

.see-more.hide{
  display:none;
}














/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  .page-container{
    max-width:1000px;
  }

  
}

@media(max-width:1200px){
  .page-container{
    max-width:800px;
  }


}

@media(max-width:1000px){
  .page-container{
    max-width:700px;
  }


}

@media(max-width:800px){
  
  .see-more{
    margin-top:0px;
  }
  .App {
    font-family: 'Founders Grotesk';
    font-size:16px;
  }

  .page-container{
    margin-left:40px;
    margin-right:40px;
    width:calc(100% - 80px);
  }
}

@media(max-width:600px){
.section-intro{
  padding-bottom:20px;
}
.section-intro h1.Title{
  font-size:2.2em;
  font-weight:600;
}
.Description{
  margin-top:5px;
}

}


@media(max-width:450px){
  .section-intro h1.Title{
    font-size:2.1em;
  }
  .Description{
    margin-top:0px;
  }
  .page-container{
    margin-left:35px;
    margin-right:35px;
    width:calc(100% - 70px);
  }
  #project-wrap>.page-container{
    margin-left:20px;
    margin-right:20px;
    width:calc(100% - 40px);
  }
}